<template>
	<div>
		<div class="width-fill flex-column-start-center">
			<div class="width-fill flex-column-start-center">
				<div class="maink">
					<div class="margin-tb-10">
						<breadcrumb></breadcrumb>
					</div>
					<div class="maink flex-row-wrap">
						<div v-for="(item,index) in list" :key="index" class="collection-item position-relative padding-13 flex-row-align-center margin-b-10"
						 :class="{'margin-r-8':(index+1)%4!=0}">
							<!-- 右上角删除 -->
							<span @click="listDelete(index)" class="iconfont icon-ashbin font-color-ccc font-size20 position-absolute"></span>
							<!-- 左边图片 -->
							<div class="collection-item-img-box flex-row-center-center">
								<img class="collection-item-img" :src="item.img">
							</div>
							<!-- 右边内容 -->
							<div class="collection-item-content flex-colum-between margin-l-10">
								<div class="collection-item-title font-color-333 twoline-show-hide">{{item.title}}</div>
								<div class="font-color-FF0000"><span class="font-size16 font-weight700 margin-r-2">{{item.goldNum}}</span><span class="font-size12">金币</span></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	import GoldMallHeader from '@/components/GoldMall/GoldMallHeader'
	import breadcrumb from '@/components/breadcrumb.vue';
	export default {
		components: {
			GoldMallHeader,
			breadcrumb
		},
		data() {
			return {
				list: []
			};
		},
		computed: {},
		mounted() {
			this.queryList();
		},
		methods: {
			queryList() {
				const copyObj = {
					title: '新鲜出炉 vivo Y70s8G+128GB蓝色版 啦啦啦啦啦',
					img: require('@/assets/imgs/GoldCoinMall/phone.png'),
					goldNum: 532
				}
				for (let i = 0; i < 30; i++) {
					let obj = JSON.parse(JSON.stringify(copyObj))
					obj.title = i + obj.title
					this.list.push(obj)
				}
			},
			listDelete(index) {
				// TODO 静态删除方法，需替换接口
				this.list.splice(index,1);
			}
		}
	}
</script>

<style lang="scss" scoped>
	.collection-item {
		width: 240px;
		height: 82px;
		border: 1px solid #f2f2f2;
		border-radius: 10px;
	}

	.icon-ashbin {
		top: 2px;
		right: 3px;
	}

	.collection-item-img-box {
		width: 84px;
		height: 84px;
		border: 1px solid #f2f2f2;
		border-radius: 5px;
	}
	
	.collection-item-img {
		max-width: 70px;
		max-height: 70px;
	}

	.collection-item-content {
		width: 134px;
		height: 86px;
	}
</style>
